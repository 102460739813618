
.flex-container {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.test-container {
  width: 100%;
  padding-bottom: 20px;
}
.word {
  background: #fff;
  width: 70%;
  height: 50px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s;
  position: relative;
  text-emphasis-style: dot;
  text-emphasis-position: under left;
  &:hover {
    color: #fc7234;
    text-emphasis-color: #fc7234;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  &.right {
    float: right;
    span {
      text-align: right;
    }
  }
  &.correct {
    animation-name: correct;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  &.wrong {
    animation-name: wrong;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.word-leave-active {
  transition: all 1s;
}

.word-leave-to {
  opacity: 0;
  transform: translateY(-80px);
}

@keyframes correct {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #9bc53d;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes wrong {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #f14668;
    color: #fff;
  }
  100% {
    background-color: #fff;
  }
}

@media only screen and (max-width: 800px) {
  .word {
    width: 100%;
    padding: 15px 10px;
    span {
      width: 100%;
    }
  }
}
